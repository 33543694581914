import React, { FC, ReactElement } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Image from '@sport1/news-styleguide/Image'
import { TagListProps } from '@sport1/types/web'
import WrappedTouchable from '@/components/WrappedTouchable'

type Props = {
    componentData: TagListProps
    testID?: string
}

const HorizontalTagList: FC<Props> = ({ componentData, testID }): ReactElement => {
    return (
        <NonFlexingContainer horizontal centerHorizontal testID={testID}>
            {componentData.content.map(tag => {
                return (
                    <NonFlexingContainer key={`${tag.url}-${tag.componentKey}`} marginX="auto">
                        <WrappedTouchable
                            webTarget="_blank"
                            testID={`${testID}-item-${tag.contextId}`}
                            href={tag.url || ''}
                        >
                            <Image size="M" imageUrl={tag.iconUrl} alt={tag.title} />
                        </WrappedTouchable>
                    </NonFlexingContainer>
                )
            })}
        </NonFlexingContainer>
    )
}

export default HorizontalTagList
